<template>
	<b-row>
		<b-col cols="12">
			<b-card title="">
				<b-overlay
					variant="white"
					:show="showLoading"
					spinner-variant="primary"
					blur="0"
					opacity=".75"
					rounded="sm"
				>
					<div class="ml-1 mb-2">
						<b-row>
							<b-col cols="12" md="9"
								   class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
							>
								<label>Mostrar</label>
								<v-select
									v-model="pageLength"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									:options="['3','5','10','20']"
									:clearable="false"
									class="per-page-selector d-inline-block mx-50"
									@input="changeLength"
								/>
								<b-button variant="primary" @click="goToCreate()">
									Crear Rol
								</b-button>
							</b-col>

							<b-col cols="12" md="3" >
								<div class="d-flex align-items-center justify-content-end">
									<b-form-input
										v-model="searchTerm"
										class="d-inline-block mr-1"
										placeholder="Cerca..."
										@update="searchRole"
									/>
								</div>
							</b-col>
						</b-row>
					</div>

					<b-table
						ref="refUserListTable"
						class="position-relative"
						:items="roles"
						responsive
						:fields="columns"
						primary-key="id"
						show-empty
						empty-text="No s'han trobat Rols"
					>
                        <template #cell(name)="data">
                            <router-link :to="{ name: 'roles_edit', params: { id: data.item.id} }">
                                {{ data.item.name }}
                            </router-link>
                        </template>

						<template #cell(created_at)="data">
							{{ formatDate(data.item.created_at) }}
						</template>

						<template #cell(department)="data">
							<b-badge :variant="statusVariant((data.item.department != undefined) ? data.item.department.name : null)">
								{{ (data.item.department != undefined) ? data.item.department.name : null }}
							</b-badge>
						</template>

						<template #cell(permissions)="data">
							<b-badge>
								{{ data.item.permissions.length }}
							</b-badge>
						</template>

						<template #cell(type)="data">
							{{ roleTypes.find(element => element.value == data.item.type).name }}
						</template>

						<template #cell(actions)="data">
							<div class="m-0">
								<router-link :to="{ name: 'roles_edit', params: { id: data.item.id} }">
									<feather-icon
										v-if="can('edit_roles')"
										:id="`invoice-row-${data.item.id}-send-icon`"
										icon="EditIcon"
										class="cursor-pointer"
										size="16"
									/>
									<b-tooltip
										title="Editar Rol"
										class="cursor-pointer"
										:target="`invoice-row-${data.item.id}-send-icon`"
									/>
								</router-link>

								<feather-icon
									v-if="can('delete_roles') && data.item.id>7"
									:id="`invoice-row-${data.item.id}-preview-icon`"
									icon="TrashIcon"
									size="16"
									class="cursor-pointer mx-1 text-red"
									@click="deleteRole(data.item.id)"
								/>
								<b-tooltip
									title="Eliminar Rol"
									:target="`invoice-row-${data.item.id}-preview-icon`"
								/>
							</div>
						</template>
					</b-table>

					<div class="mx-2 mb-2">
						<b-row>
							<b-col
								cols="12"
								sm="6"
								class="d-flex align-items-center justify-content-center justify-content-sm-start"
							>
								<span class="text-muted">Mostrant {{ pageLength }} de {{ totalRecords }} Rols</span>
							</b-col>

							<b-col
								cols="12"
								sm="6"
								class="d-flex align-items-center justify-content-center justify-content-sm-end"
							>
								<b-pagination
									:value="1"
									:total-rows="totalRecords"
									:per-page="pageLength"
									first-number
									last-number
									align="right"
									prev-class="prev-item"
									next-class="next-item"
									class="mt-1 mb-0"
									@input="changePage"
								>
									<template #prev-text>
										<feather-icon
											icon="ChevronLeftIcon"
											size="18"
										/>
									</template>
									<template #next-text>
										<feather-icon
											icon="ChevronRightIcon"
											size="18"
										/>
									</template>
								</b-pagination>
							</b-col>
						</b-row>
					</div>
				</b-overlay>
			</b-card>
		</b-col>
	</b-row>
</template>

<script>
import {BRow, BCol, BTable, BBadge, BCard, BButton, BTooltip, BPagination, BFormInput, BOverlay} from 'bootstrap-vue'
import vSelect from "vue-select"
import {mapState} from "vuex"
import Swal from "sweetalert2"
import {ROLES_CREATE} from "@/router/web/constants"
import {roleTypes} from "@/constants/role"

export default {
	name: "ListView",
	components: {
		BRow,
		BCol,
		BTable,
		BBadge,
		BCard,
		BButton,
		BTooltip,
		BPagination,
		BFormInput,
		BOverlay,

		vSelect,
	},
	data() {
		return {
			page: 1,
			pageLength: 10,
			totalRecords: 10,
			columns: [
				{key: 'name', label: 'Nom', sortable: false},
				{key: 'department', label: 'Departament', sortable: false},
				{key: 'permissions', label: 'Permisos', sortable: false},
				{key: 'type', label: 'Tipus', sortable: false},
				{key: 'created_at', label: 'Data de creació', sortable: false},
				{key: 'actions', label: 'Accions', sortable: false},
			],
			searchTerm: '',
			showLoading: false,

			roleTypes,
		}
	},
	computed: {
		statusVariant() {
			const statusColor = {
				Llar: 'light-primary',
				CO: 'light-success',
				CET: 'light-info',
				null: 'light-warning',
			}

			return status => statusColor[status]
		},
		...mapState('role', ['roles']),
	},
	created() {
		this.getRoles()
	},
	methods: {
		can(permission) {
			return this.$store.getters.can(permission)
		},
		formatDate(date) {
			const dateFormated = new Date(date)

			return dateFormated.toLocaleDateString("es-ES")
		},
		getRoles() {
			this.showLoading = true

			this.$store.dispatch('role/searchRoles', {
				relations: ['department', 'permissions'],
				per_page: this.pageLength,
				page: this.page,
				filter: this.searchTerm,
			})
				.then(response => {
					this.showLoading = false
					this.totalRecords = response.total_records
				})
				.catch(error => {
					this.showLoading = false
					console.log(error)
				})
		},
		deleteRole(id) {
			Swal.fire({
				title: "Esteu segur d'esborrar el rol?",
				text: "Un cop esborrat no ho podràs recuperar!",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#34c38f",
				cancelButtonColor: "#f46a6a",
				confirmButtonText: "Sí, esborra'l!",
				cancelButtonText: "Cancel·lar",
			}).then(result => {
				if (result.value) {
					this.$store.dispatch('role/deleteRole', {id: id})
						.then(response => {
							this.showLoading = false
							this.getRoles()
						})
						.catch(error => {
							this.showLoading = false
							console.log(error)
						})
				}
			})
		},
		changePage(page) {
            this.page = page
			this.getRoles()
		},
		changeLength(length) {
            this.length = length
			this.getRoles()
		},
		searchRole() {
			this.getRoles()
		},
		goToCreate(){
			this.$router.push(ROLES_CREATE)
		}
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
